import $ from 'jquery'

$(document).on('DOMContentLoaded', function () {
  $(document).on(
    'change',
    'form input[name="account_print[transaction_span]"]',
    () => {
      $('.print-date-selection').toggleClass('hidden')
    },
  )
})

if (window.account_selection_trigger_configured != true) {
  window.account_selection_trigger_configured = true
  $(document).on('change', '.account_selection', (event) => {
    $(event.target).parents('form').trigger('submit')
  })
}
