/* eslint-disable no-undef */
import '@/utils/accounts.js'

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

$(function () {
  $('[data-toggle="popover"]').popover()
})
